@tailwind base;
@tailwind components;
@tailwind utilities;


/* Notifications Styling */
.notifications-wrapper .notifications-tr {
  font-family: inherit;
  position: fixed;
  z-index: 9998;
  box-sizing: border-box;
  height: auto;
  top: 50px;
  bottom: auto;
  left: auto;
  right: 20px;
}
.notifications-wrapper .notifications-tr .notification {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 30px 16px 50px;
  margin-bottom: 15px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.3s ease-in-out;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
  background-color: white;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.notification-dismiss {
  position: absolute;
  top: 8px;
  right: 16px;
  color: rgb(156, 163, 175);
  font-size: 24px;
}
.notifications-wrapper .notifications-tr .notification-visible {
  opacity: 1;
}
.notifications-wrapper .notifications-tr .notification-hidden {
  opacity: 0;
}
.notifications-wrapper .notifications-tr .notification .notification-title {
  font-size: .875rem;
  margin: 0;
  padding: 0;
  font-weight: 500;
  line-height: 1.25rem;
}
.notifications-wrapper .notifications-tr .notification .notification-message {
  color: rgb(107, 114, 128);
  padding: 0;
  margin: 4px 0 0 0;
  font-size: .875rem;
  line-height: 1.25rem;
}
.notifications-wrapper .notifications-tr .notification.notification-success .notification-title:before {
    display: inline-block;
    font-style: normal;
    position: absolute;
    left: 16px;
    font-variant: normal;
    font-size: 1.25rem;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro"; font-weight: 400; content: "\f058";
    color: rgb(52, 211, 153)
}
.notifications-wrapper .notifications-tr .notification.notification-error .notification-title:before {
  display: inline-block;
  font-style: normal;
  position: absolute;
  left: 16px;
  font-variant: normal;
  font-size: 1.25rem;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro"; font-weight: 400; content: "\f057";
  color: rgb(248, 113, 113)
}